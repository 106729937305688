<template>
  <div>
    <div class="flex justify-between">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        label
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="required">
        {{ $t ? $t('sqrd_ui_required') : 'Required' }}
      </span>
    </div>
    <input
      v-bind="$attrs"
      v-on="sqrListeners"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      class="form-input"
      :class="inputClass"
      type="time"
      :value="value"
      :placeholder="placeholder || label"
    />
    <slot name="invalid" v-if="v && v.$error">
      <p class="mt-1 text-xs text-red-600" v-if="!v.required">
        {{ label }} is required.
      </p>
      <p class="mt-1 text-xs text-red-600" v-if="!v.maxLength">
        {{ label }} is too long (max {{ v.$params.maxLength.max }} chars).
      </p>
    </slot>
  </div>
</template>

<style src="./SqrInput.postcss" lang="postcss" scoped></style>

<script>
import SqrInput from './SqrInput';
export default {
  name: 'SqrdInputTime',
  mixins: [SqrInput]
};
</script>
